import React from 'react';
import { graphql } from 'gatsby';
import Page from '../components/Page';
import Post from '../components/Post';
import Heading from '../components/Heading';

function BlogList({ data }) {
  const postsByDay = data.allMarkdownRemark.edges.reduce((acc, cur) => {
    const {
      html,
      fields: { slug },
      frontmatter: { title, date },
    } = cur.node;

    const [postDate, dateGroup] = date.split('---') || ['', ''];
    const accDateGroupIndex = acc.findIndex(
      group => group.dateGroup === dateGroup
    );
    const post = {
      title,
      slug,
      html,
    };

    let addDateGroup;
    if (accDateGroupIndex > -1) {
      acc[accDateGroupIndex].posts = [...acc[accDateGroupIndex].posts, post];
    } else {
      addDateGroup = {
        dateGroup,
        date: postDate,
        posts: [post],
      };
    }

    return !!addDateGroup ? [...acc, addDateGroup] : acc;
  }, []);

  return (
    <Page title="santa likes milk too">
      {postsByDay.map(day => {
        return (
          <div key={day.dateGroup}>
            <Heading title={day.date} />
            {day.posts.map(post => {
              return (
                <div key={post.slug}>
                  <Post title={post.title} html={post.html} />
                </div>
              );
            })}
          </div>
        );
      })}
    </Page>
  );
}

export default BlogList;

export const pageQuery = graphql`
  query BlogList($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          html
          fields {
            slug
          }
          frontmatter {
            date(formatString: "dddd, MMMM DD, YYYY---MMMM YYYY DD")
            title
          }
        }
      }
    }
  }
`;
